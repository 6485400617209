import { Button } from '@/components/general/Button/Button';
import { MailingListForm } from '@/components/general/MailingListForm';
import { multipleChildren, verticalFadeIn } from '@/constants/motionProps';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { type FC } from 'react';
import { useIsClient } from 'usehooks-ts';

import type { ContentProps } from './Content.types';

export const Content: FC<ContentProps> = ({
  content = '',
  title = '',
  reverse,
}) => {
  const isClient = useIsClient();

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={multipleChildren}
      viewport={{ once: true }}
      className={clsx(['flex-1', 'flex', { 'lg:justify-end': reverse }])}
    >
      <div className="flex size-full flex-col justify-center lg:max-w-[480px]">
        {!!(isClient && title) && (
          <motion.h2
            variants={verticalFadeIn}
            className="mb-20 text-header-2-mob font-medium lg:text-header-2"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}

        {!!(isClient && content) && (
          <motion.div
            variants={verticalFadeIn}
            className="mb-36 text-text-2-mob lg:text-text-2"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}

        <MailingListForm>
          <motion.div variants={verticalFadeIn} className="flex flex-col">
            <div className="flex w-full flex-col">
              <label htmlFor="email" className="sr-only">
                Email
              </label>

              <div className="flex w-full">
                <input
                  type="email"
                  placeholder="Email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  className="page-section-newsletter-input w-full border border-primary bg-transparent p-12 text-14 placeholder:text-primary/50 focus:outline-primary lg:py-8 lg:text-20"
                />

                <Button
                  type="submit"
                  className="page-section-newsletter-button !p-12 lg:!px-12 lg:!py-14 lg:!text-14"
                >
                  Enter
                </Button>
              </div>
            </div>
          </motion.div>
        </MailingListForm>
      </div>
    </motion.div>
  );
};
